<script setup lang="ts">
interface DefaultLayoutProps {
    withReservationForm?: boolean
    offsetReservationForm?: boolean
}

defineProps<DefaultLayoutProps>()

const { category, departureAgency, returnAgency, departureTime, returnTime } = useReservationParams()
</script>

<template>
    <div class="grid">
        <header class="grid-item-full-screen">
            <VTopBar />
            <slot name="header" />
        </header>
        <LazyVReservationForm
            v-if="withReservationForm"
            :class="[
                $style['reservation-form'],
                offsetReservationForm ? 'grid-item-main-offset' : 'grid-item-main-mobile-wide',
            ]"
            :category="category"
            :departure-agency="departureAgency"
            :return-agency="returnAgency"
            :departure-time="departureTime"
            :return-time="returnTime"
        />
        <slot />
        <VFooter class="grid-item-full-screen" />
    </div>
</template>

<style lang="scss" module>
.reservation-form {
    margin-bottom: var(--spacing-md);
}
</style>
